export const PATH_ROOT = '/';

export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';

export const PATH_COMPANY = '/:companyId';
export const PATH_INVEST = '/:companyId/invest';

export const PATH_DEALS_PAGE = '/:dealId';
export const PATH_DEALS_COMPLETION = '/:dealId/invest';

export const PATH_SETTINGS = '/settings';

export const PATH_SETTINGS_BASIC = `${PATH_SETTINGS}/basic`;
export const PATH_SETTINGS_ENTITY = `${PATH_SETTINGS}/entity`;
export const PATH_SETTINGS_IDENTITY = `${PATH_SETTINGS}/identity`;

export const PATH_DASHBOARD = '/dashboard';

export const PATH_SIGNUP = '/register';
export const PATH_WALLET = '/wallet';
export const PATH_ACTIVE_DEALS = '/deals';
export const PATH_TOKEN_SALES = '/sales';
export const PATH_ALLOCATION_STAKING = '/staking';
export const PATH_SUPPORT = '/support';
