import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { PATH_LOGIN } from 'shared/constants';

const TopNavigationRightUnauthenticated = () => {
  const { companyId } = useParams();

  return (
    <>
      <Link to={`${PATH_LOGIN}${companyId ? `?companyId=${companyId}` : ''}`}>Login</Link>
      {/* <Button type="button" component={Link} to={PATH_SIGNUP} size="md">
      Get started
    </Button> */}
    </>
  );
};

export default TopNavigationRightUnauthenticated;
