import { createApi } from '@reduxjs/toolkit/query/react';
import { Entity, NewEntity } from 'models/invest/entity.api.model';
import { createBaseQuery } from '../../utils/apiUtils';
import { RootState } from 'store/store';

// Define the base URL and token-based authentication headers
export const entitiesApi = createApi({
  reducerPath: 'entitiesApi',
  tagTypes: ['Entities'],
  baseQuery: async (args, api, extraOptions) => {
    const { token } = (api.getState() as RootState).auth0;
    const baseQuery = createBaseQuery(token);
    return baseQuery(args, api, extraOptions);
  },
  endpoints: (builder) => ({
    // Fetch entities
    getEntities: builder.query<Entity[], void>({
      query: () => 'entities',
      providesTags: ['Entities'], // Tag to track entity queries
    }),
  
    // Create new entity
    createEntity: builder.mutation<Entity, NewEntity>({
      query: (newEntity) => ({
        url: `${newEntity.type === 'COMPANY' ? 'company': 'individual'}/entities`,
        method: 'POST',
        body: newEntity,
      }),
      invalidatesTags: ['Entities'], // This invalidates the entities cache after mutation
    }),

    verifyIdenty: builder.mutation<Entity, { entity_id: string; status: string }>({
      query: (payload) => ({
        url: `verifications/submit?entity_id=${payload.entity_id}&status=${payload.status}`,
        method: 'POST',
        body: undefined,
      }),
      invalidatesTags: ['Entities'], // This invalidates the entities cache after mutation
    }),
    
    // Fetch countries
    getCountries: builder.query<{ label: string; value: string }[], void>({
      query: () => 'countries',
      transformResponse: (response: Record<string, string>) =>
        Object.keys(response).map((code) => ({
          label: response[code],
          value: code,
        })),
    }),
  }),  
});

export const { useGetEntitiesQuery, useCreateEntityMutation, useGetCountriesQuery, useVerifyIdentyMutation } = entitiesApi;
