import React from 'react';
import { Card, Title } from '@mantine/core';

const PortfolioCard = ({ subtitle, title, icon , holdings}: { subtitle: string; title: string; icon: any ; holdings: number}) => (
    <Card shadow="md" p="lg" radius="md">
        <>
        <Title order={5}> Holdings ({holdings}) </Title>
        <div className="dashboard-card">
            <img src={icon} className="icon" alt="" />
            <div>
              <p className="subtitle">{subtitle}</p>
              <h3 className="title">{title}</h3>
            </div>
        </div>
       </>
    </Card>
  );


export default PortfolioCard;