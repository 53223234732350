import React from 'react';

import { Text, Title } from '@mantine/core';

import withPublicLayout from 'hocs/withPublicLayout';

import Companies from './components/Companies/Companies';

import './Homepage.scss';

const Homepage: React.FC = () => (
  <div className="homepage">
    <Title order={1} size="xx-large" weight={800}>
      Investment opportunities
    </Title>
    <Text color="dimmed">All companies are vetted and passed due diligence.</Text>
    <Companies my="lg" />
  </div>
);

export default withPublicLayout(Homepage);
