import React from 'react';

import { Avatar, Box, Group, Text, Title } from '@mantine/core';

import { useAuth0 } from '@auth0/auth0-react';

import ProfilePreviewSkeleton from './ProfilePreviewSkeleton';

const ProfilePreview: React.FC = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <ProfilePreviewSkeleton />;
  }

  return (
    <Group my="xl">
      <Avatar radius="xl" size="lg" src={user?.picture} alt={user?.name} />
      <Box>
        <Title order={2}>{user?.name}</Title>
        <Text color="gray.7">{user?.email}</Text>
      </Box>
    </Group>
  );
};

export default ProfilePreview;
