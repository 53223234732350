import React from 'react';
import { Card, Text, Group, Divider} from '@mantine/core';
import CompanyHeader from 'components/Common/CompanyHeader';
import CardTableView from 'components/Common/CardTableView';

import { getCurrencySymbol, formatNumberWithCommas, formatNumber } from 'utils/currencySymbols';


const InvestmentInfo: React.FC<{ data: any, committedAmount: number }> = ({ data, committedAmount }) =>  {
 const currency = getCurrencySymbol(data.currency || 'USD');   
 const valuation = `${currency} ${formatNumberWithCommas(data.offering_valuation)} valuation`; 

 const cardDetails = {
    label: "Committed amount",
    value: `${currency} ${formatNumber(String(committedAmount))}`
 }

 return (
    <Card shadow="md" p="lg" radius="md">
        <CompanyHeader logoUrl={data.logo_url} name={data.company_name} summary={valuation} size="sm" />
        <Group spacing={10} mt="lg" style={{ justifyContent: 'space-between'}}>
            <Text size="sm"> {data.management_fees}% Mgmt fees </Text>
            <Divider orientation="vertical" />
            <Text size="sm"> {data.carried_interest}% Carry </Text>
            <Divider orientation="vertical" />
            <Text size="sm"> {data.setup_fees || 0.5}% Setup fees </Text>
        </Group>
        <Group mt="sm" align="space-between" className="details-spacing-contatiner">
            <Divider my="s" mb={50} />
               <CardTableView 
                 field={cardDetails}
               />
        </Group>
    </Card>
  ) 
}

export default InvestmentInfo;


