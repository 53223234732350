import React from 'react';

import { Text } from '@mantine/core';
import './Footer.scss';

const Footer: React.FC = () => (
  <footer className="footer">
    <Text size="xs" align="center" color="dimmed">
      &copy; Copyright 2021-{new Date().getFullYear()} Tokenfolio. All rights reserved.
    </Text>
  </footer>
);

export default Footer;
