import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { SimpleGrid } from '@mantine/core';
// import { SimpleGridProps } from '@mantine/core/lib/SimpleGrid/SimpleGrid';
import { hideNotification, showNotification } from '@mantine/notifications';

import { companyListLoadErrorNotification } from 'pages/homepage/HomepageNotifications';
import { useGetCompanyListQuery } from 'services/company/company.service';

import CompaniesSkeleton from './CompaniesSkeleton';
import CompanyCard from './CompanyCard';

type Props = any;

const Companies: React.FC<Props> = ({ ...props }) => {
  const { data: companies, error, isLoading } = useGetCompanyListQuery();

  useEffect(() => {
    if (error) {
      showNotification(companyListLoadErrorNotification);
    }
    return () => {
      hideNotification(companyListLoadErrorNotification.id as string);
    };
  }, [error]);

  if (error || isLoading || !companies) {
    return <CompaniesSkeleton />;
  }
  return (
    <SimpleGrid breakpoints={[{ maxWidth: 768, cols: 1, spacing: 'sm' }]} cols={3} spacing="xl" {...props}>
      {companies.map(
        ({ id, bannerUrl, location, logoUrl, name, summary, tags, dealEnd, dealStart, hasDetailsPage }) => {
          const companyCard = (
            <CompanyCard
              key={id}
              bannerUrl={bannerUrl}
              location={location}
              logoUrl={logoUrl}
              name={name}
              summary={summary}
              tags={tags}
              dealEnd={dealEnd}
              dealStart={dealStart}
            />
          );
          if (!hasDetailsPage) {
            return companyCard;
          }
          // if (id === 'groq') {
          //   return (
          //     <a href="https://www.tokenfolio.co/deals-groq" key={id}>
          //       {companyCard}
          //     </a>
          //   );
          // }
          // if (id === 'space-x-5') {
          //   return (
          //     <a href="https://www.tokenfolio.co/spacex" key={id}>
          //       {companyCard}
          //     </a>
          //   );
          // }
          return (
            <Link key={id} to={id}>
              {companyCard}
            </Link>
          );
        }
      )}
    </SimpleGrid>
  );
};

export default Companies;
