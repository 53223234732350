// eslint-disable-next-line import/prefer-default-export
import { PATH_SETTINGS_BASIC } from './constants';

export enum ErrorCode {
  NOT_FOUND = 404,
}

export enum ActionType {
  Submit = 'submit',
  Cancel = 'cancel',
  Error = 'error',
}

export enum DocumentStatus {
  Pending = 'pending',
  Signed = 'signed',
  Canceled = 'canceled',
}

export const InvestmentStatus = {
  COMMITTED: { label: 'Committed', value: 'COMMITTED', rank: 1 },
  WIRED: { label: 'Wired', value: 'WIRED', rank: 2  },
  FUNDS_RECEIVED: { label: 'Funds Received', value: 'FUNDS_RECEIVED', rank: 3 },
  COMPLETED: { label: 'Completed', value: 'COMPLETED', rank: 4  },
} as const;

export const EntityStatus = {
  NOT_VERIFIED: { label: 'Verification Pending - Click to Verify', value: 'NOT_VERIFIED', url: PATH_SETTINGS_BASIC , color: '#FFE066'},
  VERIFIED: { label: 'Verified', value: 'VERIFIED', url: '', color: 'rgb(64, 192, 87)'  },
  UNDER_REVIEW : { label: 'Under Review (Would take some time)', value: 'UNDER_REVIEW', url: '', color: 'var(--toastify-color-info)'  },
  DENIED: { label: 'Denied', value: 'DENIED', url: '', color: 'var(--toastify-color-error)'  },
} as const;


export const EntityType = {
  COMPANY: { label: 'Firm', value: 'COMPANY', indenfyBaseUrl: 'https://kyb.ui.idenfy.com/welcome?authToken=' },
  INDIVIDUAL: { label: 'Individual', value: 'INDIVIDUAL', indenfyBaseUrl: 'https://ui.idenfy.com/?authToken=' }
} as const;