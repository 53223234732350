import React from 'react';

import { SimpleGrid, Skeleton } from '@mantine/core';

const CompaniesSkeleton: React.FC = () => (
  <SimpleGrid breakpoints={[{ maxWidth: 768, cols: 1, spacing: 'sm' }]} cols={3} spacing="xl" my="lg">
    <Skeleton height={480} />
    <Skeleton height={480} />
    <Skeleton height={480} />
  </SimpleGrid>
);

export default CompaniesSkeleton;
