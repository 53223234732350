import designTokens from './designTokens';

const theme: any = {
  colors: {
    primary: designTokens.colors.primary,
    secondary: designTokens.colors.secondary,
  },
  colorScheme: 'light',
  white: '#fff',
  black: '#000000',
  primaryColor: 'primary',
  primaryShade: 6,
  defaultRadius: 'md',
  fontFamily: 'Arial, sans-serif',
  fontSizes: {
    xs: parseInt(designTokens.typography.fontSize.xSmall, 10),
    sm: parseInt(designTokens.typography.fontSize.small, 10),
    md: parseInt(designTokens.typography.fontSize.medium, 10),
    lg: parseInt(designTokens.typography.fontSize.large, 10),
    xl: parseInt(designTokens.typography.fontSize.xlarge, 10),
    xxl: parseInt(designTokens.typography.fontSize.xxllarge, 10),
  },
  fontWeights: {
    regular: designTokens.typography.fontWeight.regular,
    medium: designTokens.typography.fontWeight.medium,
    bold: designTokens.typography.fontWeight.bold,
  },
  spacing: {
    xs: parseInt(designTokens.spacing.xs, 10),
    sm: parseInt(designTokens.spacing.sm, 10),
    md: parseInt(designTokens.spacing.md, 10),
    lg: parseInt(designTokens.spacing.lg, 10),
    xl: parseInt(designTokens.spacing.xl, 10),
  },
  shadows: {
    xs: designTokens.shadows.small,
    sm: designTokens.shadows.small,
    md: designTokens.shadows.medium,
    lg: designTokens.shadows.large,
    xl: designTokens.shadows.large,
  },

  headings: {
    // fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: designTokens.typography.headingSize.h1 },
      h2: { fontSize: designTokens.typography.headingSize.h2 },
      h3: { fontSize: designTokens.typography.headingSize.h3 },
      h4: { fontSize: designTokens.typography.headingSize.h4 },
      h5: { fontSize: designTokens.typography.headingSize.h5 },
      h6: { fontSize: designTokens.typography.headingSize.h6 },
    },
  },
  globalStyles: () => ({
    "a:not([data-button='true'])": {
      textDecoration: 'none',
    },
  }),
};

export default theme;
