/* eslint-disable no-nested-ternary */
import React from 'react';

import { Title } from '@mantine/core';
import PortfolioCard from './PorfolioCard';

const PortfolioSettingsPage: React.FC = () => (
  <>
    <Title order={1} my="lg">
      Personal Portfolio
    </Title>

    <PortfolioCard
      company="Some Company"
      investedAmount={2000000}
      shares={30}
      perShareAmount={66666.6666666667}
      securityType="Equity"
    />
  </>
);

export default PortfolioSettingsPage;
