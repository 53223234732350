import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { InvestmentStatus } from 'shared/enums';
import {
  IconFileDownload,
} from '@tabler/icons';
import { Button, Tooltip } from "@mantine/core";

interface SubscriptionLinkProps {
  data: any; // You can replace 'any' with the correct type if available from Ag-Grid
  value: string;
}

const SubscriptionLink: React.FC<SubscriptionLinkProps> = ({ data, value}) => {
  const { user: authenticatedUser } = useAuth0();
  const signatureId = value;
  const { deal_id: dealID, signature_request_id: signatureRequestId, status } = data;
  const statusVal = InvestmentStatus[data.status as keyof typeof InvestmentStatus];

  const subscriptionDocument = `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  }/o/users%2Fdocuments%2F${authenticatedUser?.sub}%2F${dealID}%2F${signatureId}.pdf?alt=media`;

  const acceptanceDocument = statusVal.value === InvestmentStatus.COMPLETED.value && `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  }/o/users%2Fdocuments%2F${authenticatedUser?.sub}%2F${dealID}%2F${signatureRequestId}.pdf?alt=media`;


  return (
    <>
     <Tooltip label="Subscription document" withinPortal>
        <Button
          component="a"
          variant="subtle"
          href={subscriptionDocument}
          target="_blank"
          rel="noopener noreferrer"
          fz="md"
          px={5}
          onClick={(event) => event.stopPropagation()}
        >
          <IconFileDownload />
        </Button>
     </Tooltip>

      {
        acceptanceDocument &&
        <Tooltip label="Acceptance document" withinPortal>
            <Button
              component="a"
              variant="subtle"
              href={acceptanceDocument}
              target="_blank"
              rel="noopener noreferrer"
              fz="md"
              px={5}
              onClick={(event) => event.stopPropagation()}
            >
              <IconFileDownload />
            </Button>
        </Tooltip>
      }
    </>

  );
};

export default SubscriptionLink;
