import React from 'react';
import { Stack, Title, Checkbox, Text, Anchor } from '@mantine/core';
import { createStyles } from '@mantine/core';

interface TermsAndPaymentProps {
  checkboxValue: string[];
  setCheckboxValue: (value: string[]) => void;
  termSheet: string;
  showError: boolean;
}

const useStyles = createStyles((theme) => ({
  checkboxError: {
    backgroundColor: '#FF8787',
    transition: 'background-color 0.3s ease',
  },
}));

const TermsAndPayment: React.FC<TermsAndPaymentProps> = ({ checkboxValue, setCheckboxValue, termSheet, showError }) => {
  const { classes } = useStyles();

  const isSelected = (value: string) => checkboxValue.includes(value);

  return (
    <Stack className="invest-termspayment" mt="md">
      <Title order={3} className="invest-termspayment--title">Terms & payment</Title>
      <Checkbox.Group value={checkboxValue} onChange={setCheckboxValue} orientation="vertical">
        <Checkbox
          radius="xs"
          value="subscription_agreement"
          classNames={{
            input: showError && !isSelected('subscription_agreement') ? classes.checkboxError : '',
          }}
          label={
            <Text fz="md">
              I agree to the terms and provisions of the{' '}
              <Anchor size="sm" href={termSheet} target="_blank" className="highlighted-anchor">Subscription Agreement</Anchor>
            </Text>
          }
        />
        <Checkbox
          radius="xs"
          value="privacy_policy_consent"
          classNames={{
            input: showError && !isSelected('privacy_policy_consent') ? classes.checkboxError : '',
          }}
          label={
            <Text fz="md">
              I agree to the{' '}
              <Anchor size="sm" href="https://www.tokenfolio.co/terms-of-service" target="_blank" className="highlighted-anchor">Terms of Service</Anchor> &{' '}
              <Anchor size="sm" href="https://www.tokenfolio.co/privacy-policy" target="_blank" className="highlighted-anchor">Privacy Policy</Anchor>
            </Text>
          }
        />
        <Checkbox
          radius="xs"
          value="risk_factors"
          classNames={{
            input: showError && !isSelected('risk_factors') ? classes.checkboxError : '',
          }}
          label={
            <Text fz="md">
              I understand that this investment involves substantial risks and acknowledge risks outlined in the{' '}
              <Anchor size="sm" href="https://www.tokenfolio.co/risks" target="_blank" className="highlighted-anchor">Risk Factors</Anchor>
            </Text>
          }
        />
      </Checkbox.Group>
    </Stack>
  );
};

export default TermsAndPayment;
