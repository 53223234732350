import React from 'react';
import { Link } from 'react-router-dom';

import { Group, Skeleton } from '@mantine/core';

import { useAuth0 } from '@auth0/auth0-react';

import { PATH_ROOT, PATH_DASHBOARD } from 'shared/constants';
import logo from 'assets/tokenfolio-black.svg';
import TopNavigationRightAuthenticated from './TopNavigationRightAuthenticated';
import TopNavigationRightUnauthenticated from './TopNavigationRightUnauthenticated';
import { Button } from '@mantine/core';
import { NavLink } from "react-router-dom";
import './TopNavigation.scss';


const MyButton = () => (
    <NavLink
      to={PATH_DASHBOARD}
      style={{ textDecoration: "none" }} // Ensuring no underline
    >
      {({ isActive }) => (
        <Button
          variant="subtle"
          styles={() => ({
            root: {
              color: isActive ? "var(--mantine-color-primary-6)" : "var(--mantine-color-dark-7)",
              '&:hover': {
                color: "var(--mantine-color-primary-6)"
              },
              borderRadius: 4
            },
          })}
        >
          Dashboard
        </Button>
      )}
    </NavLink>
  );

const TopNavigation: React.FC = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const roles = user?.[`${process.env.REACT_APP_ROOT_DOMAIN || ''}/roles`];
  const hasDashboardAccess = roles?.includes('verified');

  const topNavigationRight = isAuthenticated ? (
    <TopNavigationRightAuthenticated />
  ) : (
    <TopNavigationRightUnauthenticated />
  );

  return (
    <nav className="top-navigation">
      <Group position="left" className="top-navigation__left-side">
        <Link to={PATH_ROOT}>
          <img className="top-navigation__left-side__logo" src={logo} alt="Tokenfolio" />
        </Link>
      </Group>
      <Group position="right" spacing="lg" className="top-navigation__right-side">
        {hasDashboardAccess && <MyButton />}
        {isLoading ? <Skeleton height={36} width={36} radius={36} /> : topNavigationRight}
      </Group>
    </nav>
  );
};

export default TopNavigation;
