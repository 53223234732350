/**
 * !!! DISCLAIMER: DO NOT USE !!!
 * this slice was only used to set up and test redux setup
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store/store';

type CompanyState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState = {} as CompanyState;

export const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    load: (state, action: PayloadAction<string>) => ({
      ...state,
      error: null,
      isLoading: true,
    }),
    loadSuccess: (state) => ({
      ...state,
      error: null,
      isLoading: false,
    }),
    loadFailure: (state, { payload: error }: PayloadAction<Error>) => ({
      ...state,
      error,
      isLoading: false,
    }),
  },
});

export const { load, loadSuccess, loadFailure } = slice.actions;

export const selectCompany = (state: RootState) => state.company;

export default slice.reducer;
