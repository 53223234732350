// src/components/Common/DetailsContainer.tsx
import React from 'react';
import { Box, Text, Group, Title, Divider, Anchor } from '@mantine/core';
import './DetailsContainer.scss';
import { FieldTypesEnum, formatDate } from 'utils/datetime';
import { getCurrencySymbol, formatNumberWithCommas } from 'utils/currencySymbols';
import CardTableView from '../CardTableView';

type Field = {
  key: string;
  label: string;
  type: FieldTypesEnum;
};

type Props = {
  fields: Field[];
  data: Record<string, any>;
  className?: string;
  currencyCode?: string;
};

const Links:Record<string, string> = {
  Direct: "https://www.tokenfolio.co/faqs?questionId=08d65855-709a-41e9-9806-7d1e1c3fa6e4&appDefId=14c92d28-031e-7910-c9a8-a670011e062d",
  Indirect: "https://www.tokenfolio.co/faqs?questionId=08d65855-709a-41e9-9806-7d1e1c3fa6e4&appDefId=14c92d28-031e-7910-c9a8-a670011e062d"
}

const DetailsContainer: React.FC<Props> = ({ fields, data, className, currencyCode }) => {
  const currency = getCurrencySymbol(currencyCode || "USD");
  const formatCurrency = (value: number) => `${currency}${formatNumberWithCommas(value)}`;
  const formatPercentage = (value: number) => `${value}%`;
  const formatDateTime = (value: string) => formatDate(value, "date");
  const formatLink = (value: string) => {
    const link = Links[value] || Links.Indirect;
    return (
      <Anchor href={link} className="link-anchor" target="_blank">
        {value}
      </Anchor>
  )};

  const renderFieldValue = (field: Field, data2: any): React.ReactNode => {
    const value = field.key.split('.').reduce((acc, part) => acc && acc[part], data2);

    if (value === undefined || value === null) return null;

    switch (field.type) {
      case FieldTypesEnum.Currency:
        return formatCurrency(value);
      case FieldTypesEnum.Percentage:
        return formatPercentage(value);
      case FieldTypesEnum.DateTime:
        return formatDateTime(value);
      case FieldTypesEnum.Link:
        return formatLink(value);
      case FieldTypesEnum.String:
      default:
        return value;
    }
  };

  return (
    <Group spacing="xs" mt="md" align="space-between" className={`details-spacing-contatiner ${className}`}>
      {fields.map((field, index) => {
        const fieldValue = renderFieldValue(field, data);
        if (!fieldValue) return null;

        const customClassName = field.type === FieldTypesEnum.Link ? 'link-field' : '';

        return (
          <React.Fragment key={field.key}>
            <CardTableView 
              field={{ label: field.label, value: fieldValue }}
              className={customClassName}
            />
            {index !== fields.length - 1 && <Divider my="s" mb={50} />}
          </React.Fragment>
        );
      })}
    </Group>
  );
};

DetailsContainer.defaultProps = {
  className: '',
  currencyCode: 'USD'
};

export default DetailsContainer;
