import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { ref, getBytes } from 'firebase/storage';
import { Parser } from 'xml2js';

import { CompanyApi, CompanyListApi } from 'models/company/company.api.model';
import { Company, CompanyOverview } from 'models/company/company.model';
import { firebaseStorage } from 'services/firebase/firebase.service';

import { mapCompanyApiToCompany, mapCompanyListApiToCompanyList } from './company.service.utils';

const xmlParser = new Parser({
  trim: true,
  normalize: true,
  mergeAttrs: true,
  strict: true,
  explicitArray: false,
  charkey: 'value',
  preserveChildrenOrder: true,
});

const textDecoder = new TextDecoder();

const getCompanyQueryFn = async (companyId: string) => {
  const companyXMLBytes = await getBytes(ref(firebaseStorage, `companies/${companyId}/profile.xml`));
  const companyXMLString = textDecoder.decode(companyXMLBytes);
  const companyApiData = (await xmlParser.parseStringPromise(companyXMLString)) as CompanyApi;
  return { data: mapCompanyApiToCompany(companyApiData) };
};

const getCompanyListQueryFn = async () => {
  const companyListXMLBytes = await getBytes(ref(firebaseStorage, `companies/index.xml`));
  const companyListXMLString = textDecoder.decode(companyListXMLBytes);
  const companyListApiData = (await xmlParser.parseStringPromise(companyListXMLString)) as CompanyListApi;
  return { data: mapCompanyListApiToCompanyList(companyListApiData) };
};

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    getCompany: builder.query<Company, string>({
      queryFn: getCompanyQueryFn,
    }),
    getCompanyList: builder.query<CompanyOverview[], void>({
      queryFn: getCompanyListQueryFn,
    }),
  }),
});

export const { useGetCompanyQuery, useGetCompanyListQuery } = companyApi;
