// src/pages/DealsPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Text, Group, Badge, Box, Title, Button } from '@mantine/core';
import { getCurrencySymbol } from 'utils/currencySymbols';
import { useNavigate } from 'react-router-dom';

import { useGetDealsQuery } from 'services/deals/deals.service';
import DealsPageSkeleton from './DealsPageSkeleton';
import NotFound from 'pages/error/404/components/NotFound';
import CompanyHeader from 'components/Common/CompanyHeader';
import './DealsPage.scss';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvestment } from 'components/Auth0/slices/auth0Slice';
import { PATH_LOGIN, PATH_SETTINGS_BASIC } from 'shared/constants';
import InvestmentForm from './InvestmentForm';
import { setLocalStorage } from 'utils/browserUtil';
import { useAppSelector } from 'store/hooks';
import { selectShouldConfirm } from 'pages/settings/SettingsSlice';

const CompanyInfo: React.FC<{ data: any }> = ({ data }) => (
  <Box className="company-info">
    <CompanyHeader logoUrl={data.logo_url} name={data.company_name} summary={data.company_summary} size="lg" />
    <Group spacing={6} my="lg" className="company-profile">
      {data.tags.map((tag: any) => (
          <Badge key={tag} radius="sm">
            {tag}
          </Badge>
        ))}
    </Group>
  </Box>
);

const CompanyDetails: React.FC<{ data: any }> = ({ data }) => (
  <Box className="company-details">
    <Title order={3} weight={500}>
      Company
    </Title>
    <Text fz="md" mt={20} color="var(--mantine-color-dark-7)">
      {data.company_info}
    </Text>
  </Box>
);


const InvestMentMemo: React.FC<{ link: string }> = ({ link }) => (
  <Box className="company-investment-memo">
    <Title order={3} weight={500}  mt={30}>
       Investment Memo
    </Title>
    <Button
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      variant="filled"
      fz="md" mt={20}
      >
      View investment Memo
    </Button>
  </Box>
);


const DealsComponent: React.FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const { data, error, isLoading } = useGetDealsQuery(dealId || '');
  const user = useSelector((state: any) => state.auth0.user);
  const domainUrl = process.env.REACT_APP_ROOT_DOMAIN || '';
  // TODO: change this before
  const isUserVerified = user?.[`${domainUrl}/roles`]?.includes('verified');
  const shouldConfirm = useAppSelector(selectShouldConfirm);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInvestClick = (investmentValue: number) => {
    if (!user) {
      navigate(PATH_LOGIN); // Redirect to login
    } else if (shouldConfirm) {
      // Show verification prompt
      navigate(PATH_SETTINGS_BASIC);
    } else {
      // Proceed with investment
      setLocalStorage(`${dealId}-investment`, investmentValue);
      dispatch(updateInvestment({ dealId: dealId || '', investmentValue }));
      navigate(`/${dealId}/invest`)
    }
  };

  if (isLoading) {
    return <DealsPageSkeleton />;
  }

  if (error || !data) {
    return <NotFound />;
  }

  const currency = getCurrencySymbol(data.currency || 'USD');

  return (
    <Box className="company-details-container">
      <Box className="left-column">
        <CompanyInfo data={data} />
        <CompanyDetails data={data} />
        <InvestMentMemo link={data.investment_memo_document_url ||  ''} />
      </Box>
      <Box className="company-deals-term">
        <InvestmentForm data={data} currency={currency} isUserVerified={!shouldConfirm} user={user} onInvestClick={handleInvestClick} />
      </Box>
    </Box>
  );
};

export default DealsComponent;
