// Utility to handle Local Storage operations

/**
 * Sets an item in local storage.
 * @param key - The key to store the value under.
 * @param value - The value to store (string, object, or array).
 */
export const setLocalStorage = (key: string, value: any) => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error saving data to local storage: ${error}`);
    }
  };
  
  /**
   * Gets an item from local storage.
   * @param key - The key of the item to retrieve.
   * @returns The parsed value from local storage, or null if the key doesn't exist.
   */
  export const getLocalStorage = (key: string) => {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error(`Error reading data from local storage: ${error}`);
      return null;
    }
  };
  
  /**
   * Removes an item from local storage.
   * @param key - The key of the item to remove.
   */
  export const removeLocalStorage = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing data from local storage: ${error}`);
    }
  };
  
  /**
   * Clears all items from local storage.
   */
  export const clearLocalStorage = () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing local storage: ${error}`);
    }
  };
  