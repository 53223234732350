export enum CompanyPitchSection {
  PROBLEM = 'problem',
  SOLUTION = 'solution',
  PRODUCT = 'product',
  TRACTION = 'traction',
  CUSTOMERS = 'customers',
  // TEAM = 'team',
  DECK = 'deck',
  BUSINESS_MODEL = 'business-model',
  MARKET = 'market',
  COMPETITION = 'competition',
  VISION_AND_STRATEGY = 'vision-and-strategy',
  FUNDING = 'funding',
  FOUNDERS = 'founders',
  SUMMARY = 'summary',
  HEADER = 'header',
  FOOTER = 'footer',
}

export enum CompanyTag {
  AI = 'AI',
  HARDWARE = 'hardware',
  SAAS = 'saas',
  SOCIAL = 'social',
  MARTECH = 'martech',
  TRANSPORT = 'transport',
  SPACE_TECH = 'spacetech',
  DEEP_TECH = 'deeptech',
  APPS = 'apps',
  B2C = 'b2c',
  DELIVERY = 'delivery',
  ENTERTAINMENT = 'entertainment',
  FASHION = 'fashion',
  FINTECH = 'fintech',
  INVESTMENT = 'investment',
  LEARNING = 'learning',
  MARKETING = 'marketing',
  NUTRITION = 'nutrition',
  SOFTWARE = 'software',
  SUSTAINABILITY = 'sustainability',
  EV = 'EV',
  MANUFACTURING = 'manufacturing',
  DEV_TOOLS = 'devtools',
  SPACE = 'space',
}

export enum InvestmentSecurity {
  TOKENS_SAFT = 'tokens-saft',
  TOKENS_EQUITY = 'tokens-equity',
  EQUITY = 'Equity',
  CCPS = 'CCPS',
  CCD = 'CCD',
  SAFE = 'SAFE',
  CONVERTIBLE_NOTE = 'convertible-note',
}

export enum BannerType {
  IMAGE = 'image',
  YOUTUBE = 'youtube',
  VIDEO = 'video',
}
