// columnDefs.ts
import { ColDef } from 'ag-grid-community';
import StatusDropdown from '../components/StatusDropdown';
import SubscriptionLink from '../components/SubscriptionLink';
import WiredData from '../components/WiredData';
import ConfirmWired from '../components/ConfirmWired';
import { formatNumber } from 'utils/currencySymbols';


export const columnDefs: ColDef[] = [
  {
    headerName: 'Entity Name',
    field: 'entity_name',
  },
  {
    headerName: 'Entity Type',
    field: 'entity_type',
  },
  {
    headerName: 'Company',
    field: 'company',
  },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: (params) => `$${formatNumber(params.value)}`, // Ensure correct syntax
  },
  {
    headerName: 'Date',
    field: 'timestamp',
    sortable: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(), // Ensure correct syntax
  },
  {
    headerName: 'Documents',
    field: 'signature_id',
    cellRenderer: SubscriptionLink,
    minWidth: 100, 
  },
  {
    headerName: 'Status',
    field: 'status',
    cellRenderer: StatusDropdown
  },
  {
    headerName: '',
    field: '',
    cellRenderer: WiredData
  },
  {
    headerName: '',
    field: '',
    cellRenderer: ConfirmWired
  },
];
