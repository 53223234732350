// src/components/FirstTimeInvestor.tsx
import React from 'react';
import { Box, Title } from '@mantine/core';
import './index.scss'; // Ensure you import your stylesheet

const FirstTimeInvestor = () => (
  <Box className="first-time-disclaimer">
    <Title order={3} weight={500}>First time investors, please read:</Title>
    <ul>
      <li>I acknowledge that Tokenfolio will not be responsible for any of my investment losses.</li>
      <li>I understand that many startups fail and I may lose my entire investment.</li>
      <li>I understand that my interests may be diluted in any subsequent company financing and I do not have a right to participate in such financing.</li>
      <li>I understand that I cannot rely on any forward-looking statements regarding any startup.</li>
    </ul>
  </Box>
);

export default FirstTimeInvestor;
