import React from 'react';
import { Input, Text } from '@mantine/core';
import { formatNumber } from 'utils/currencySymbols';

const InvestmentInput: React.FC<{
  currency: string;
  minInvestment: number;
  investmentValue: number | undefined;
  setInvestmentValue: (value: number) => void;
  isDisabled?: boolean;
  label?: string;
  required?: boolean
  showErrorMessage?: boolean
}> = ({ currency, minInvestment, investmentValue, setInvestmentValue, isDisabled, label, required, showErrorMessage }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    setInvestmentValue(Number(value)); // Update state with numeric value
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      event.preventDefault(); // Prevent non-numeric keys
    }
  };

  return (
    <Input.Wrapper label={ label && <Text fz="md">{label}</Text>}
       error={
        showErrorMessage && 
        (!investmentValue || investmentValue < minInvestment)
          ? `Minimum investment amount is ${currency}${minInvestment}` 
          : ''
       }
       >
      <Input
        sx={{ minWidth: '300px' }}
        icon={<div>{currency}</div>}
        placeholder={`Minimum investment ${currency}${formatNumber(minInvestment)}`}
        radius="sm"
        value={investmentValue && formatNumber(investmentValue)} // Display formatted value
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
      />
    </Input.Wrapper>
  );
};

InvestmentInput.defaultProps = {
    isDisabled: false,
    label: '',
    required: false,
    showErrorMessage: false,
}
export default InvestmentInput;
