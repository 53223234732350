import React from 'react';

import { Box, Group, Skeleton, Stack } from '@mantine/core';

const ProfilePreviewSkeleton: React.FC = () => (
  <Group>
    <Skeleton height={60} width={60} radius={60} />
    <Box>
      <Stack spacing="xs">
        <Skeleton height={24} width={240} />
        <Skeleton height={18} width={120} />
      </Stack>
    </Box>
  </Group>
);

export default ProfilePreviewSkeleton;
