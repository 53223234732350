import { useAuth0 } from '@auth0/auth0-react';
import { useGetPortfolioQuery } from 'services/invest/portfolio.service';
import { useEffect, useState } from 'react';

export const useGetPortfolioWithAuth = () => {
  const { isAuthenticated, isLoading: isAuthLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) {
      (async () => {
        try {
          const tokenV = await getAccessTokenSilently();
          setToken(tokenV);
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      })();
    }
  }, [isAuthenticated, isAuthLoading, getAccessTokenSilently]);

  const skip = !isAuthenticated || !token || isAuthLoading;
  const portfolioQuery = useGetPortfolioQuery(undefined, { skip });

  return {
    ...portfolioQuery,
    isAuthLoading,
    token,
    isAuthenticated,
  };
};
