import React from 'react';
import { Avatar, Box, Group, Text, Title } from '@mantine/core';

type Props = {
  logoUrl: string;
  name: string;
  summary: string;
  size: 'lg' | 'sm' | 'xs';
};

const sizes = {
  lg: {
    avatar: 80,
    title: '2rem',
    text: '1.025rem',
    textWeight: "500",
  },
  sm: {
    avatar: 40,
    title: '1.25rem',
    text: '0.875rem',
    textWeight: "550",
  },
  xs: {
    avatar: 20,
    title: '1rem',
    text: '0.875rem',
    textWeight: "550",
  },
};

const CompanyHeader: React.FC<Props> = ({ logoUrl, name, summary, size = 'lg' }) => {
  const { avatar, title, text, textWeight } = sizes[size] as any;

  return (
    <Box>
      <Group noWrap align="center">
        <Avatar size={avatar} src={logoUrl} alt={name} />
        <Box>
          <Title order={1} size={title} weight={550}>
            {name}
          </Title>
          <Text color="dimmed" size={text} mt={4} weight={textWeight}>
            {summary}
          </Text>
        </Box>
      </Group>
    </Box>
  );
};

export default CompanyHeader;
