import { BannerType, CompanyPitchSection, CompanyTag, InvestmentSecurity } from './company.model.enums';

export type Company = {
  id: string;
  bannerUrl: {
    type: BannerType;
    value: string;
  };
  documents: {
    termSheet: string;
  };
  socials: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    webpage?: string;
  };
  location: {
    city: string;
    country: string;
  };
  dealTerms: {
    deadline: string;
    fundingGoal: {
      from: string;
      to: string;
    };
    maxInvestment: string;
    minInvestment: string;
    nomineeLead: string;
    security: InvestmentSecurity;
    valuationCap: string;
    dealStart: string;
    dealEnd: string;
  };
  highlights: string[];
  founders: {
    founder: CompanyFounder[];
  };
  logoUrl: string;
  name: string;
  sections: {
    type: CompanyPitchSection;
    value: string;
  }[];
  summary: string;
  tags: CompanyTag[];
};

export type CompanyOverview = {
  id: string;
  bannerUrl: string;
  dealStart: string;
  dealEnd: string;
  location: {
    city: string;
    country: string;
  };
  logoUrl: string;
  name: string;
  summary: string;
  tags: CompanyTag[];
  hasDetailsPage?: boolean;
};

export type CompanyFounder = {
  linkedin: string;
  name: string;
  photoURL: string;
  profile: string;
  title: string;
};

export const companyPitchSectionTitle = {
  [CompanyPitchSection.BUSINESS_MODEL]: 'Business model',
  [CompanyPitchSection.COMPETITION]: 'Competition',
  [CompanyPitchSection.CUSTOMERS]: 'Customers',
  [CompanyPitchSection.FUNDING]: 'Funding',
  [CompanyPitchSection.MARKET]: 'Market',
  [CompanyPitchSection.FOUNDERS]: 'Founders',
  [CompanyPitchSection.TRACTION]: 'Traction',
  [CompanyPitchSection.PROBLEM]: 'Problem',
  // [CompanyPitchSection.TEAM]: 'Team',
  [CompanyPitchSection.DECK]: 'Deck',
  [CompanyPitchSection.PRODUCT]: 'Product',
  [CompanyPitchSection.SOLUTION]: 'Solution',
  [CompanyPitchSection.SUMMARY]: 'Summary',
  [CompanyPitchSection.VISION_AND_STRATEGY]: 'Vision and strategy',
  [CompanyPitchSection.HEADER]: 'Header',
  [CompanyPitchSection.FOOTER]: 'Footer',
};

export const companyTagLabel = {
  [CompanyTag.AI]: 'AI',
  [CompanyTag.HARDWARE]: 'Hardware',
  [CompanyTag.APPS]: 'Apps',
  [CompanyTag.B2C]: 'B2C',
  [CompanyTag.DELIVERY]: 'Delivery',
  [CompanyTag.ENTERTAINMENT]: 'Entertainment',
  [CompanyTag.FASHION]: 'Fashion',
  [CompanyTag.FINTECH]: 'Fintech',
  [CompanyTag.INVESTMENT]: 'Investment',
  [CompanyTag.LEARNING]: 'Learning',
  [CompanyTag.MARKETING]: 'Marketing',
  [CompanyTag.NUTRITION]: 'Nutrition',
  [CompanyTag.SOFTWARE]: 'Software',
  [CompanyTag.SUSTAINABILITY]: 'Sustainability',
  [CompanyTag.EV]: 'EV',
  [CompanyTag.MANUFACTURING]: 'Manufacturing',
  [CompanyTag.SAAS]: 'SAAS',
  [CompanyTag.SOCIAL]: 'Social',
  [CompanyTag.MARTECH]: 'Martech',
  [CompanyTag.TRANSPORT]: 'Transport',
  [CompanyTag.SPACE_TECH]: 'Space Tech',
  [CompanyTag.DEEP_TECH]: 'Deep Tech',
  [CompanyTag.DEV_TOOLS]: 'Dev Tools',
  [CompanyTag.SPACE]: 'Space',
};

export const companySecurityLabel = {
  [InvestmentSecurity.TOKENS_EQUITY]: 'Tokens (Equity)',
  [InvestmentSecurity.TOKENS_SAFT]: 'Tokens (SAFT)',
  [InvestmentSecurity.EQUITY]: 'Equity',
  [InvestmentSecurity.CCPS]: 'CCPS',
  [InvestmentSecurity.CCD]: 'CCD',
  [InvestmentSecurity.SAFE]: 'SAFE',
  [InvestmentSecurity.CONVERTIBLE_NOTE]: 'Convertible Note',
};
