import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';

import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const [params] = useSearchParams();

  const domain = process.env.REACT_APP_DOMAIN || '';

  function base64URLEncode(str: CryptoJS.lib.WordArray) {
    const base64 = CryptoJS.enc.Base64.stringify(str);
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
  }

  function sha256(buffer: CryptoJS.lib.WordArray) {
    // const wordArray = CryptoJS.enc.Utf8.parse(buffer);
    const hash = CryptoJS.SHA256(buffer);
    return CryptoJS.enc.Base64.parse(hash.toString(CryptoJS.enc.Base64));
  }

  const generateCodeChallenge = () => {
    const verifier = CryptoJS.lib.WordArray.random(32);
    const challenge = base64URLEncode(sha256(verifier));
    return challenge;
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void (async () => {
      await loginWithRedirect({
        code_challenge_method: 'S256',
        code_challenge: generateCodeChallenge(),
        redirectUri: `${domain}/${params.get('dealId') || ''}`,
      });
    })();
  }, [loginWithRedirect]);

  return null;
};

export default Login;
