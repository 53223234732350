export interface Field {
    name: string;
    label: string;
    required: boolean;
  }
  
export interface AccreditationOption {
    value: string;
    label: string;
    description: string;
  }
  
export interface EntityConfig {
    fields: Field[];
    accreditation: AccreditationOption[];
}

export const formConfig: { [entityType: string]: EntityConfig } = { 
    INDIVIDUAL: {
        fields: [
          { name: 'first_name', label: 'First Name', required: true },
          { name: 'last_name', label: 'Last Name', required: true },
          { name: 'phone_number', label: 'Phone Number', required: true },
          { name: 'email', label: 'Email ID', required: true },
        ],
        accreditation: [
          { value: 'NET_ASSETS', label: 'Net assests' , description: '$1M USD or more in net assets, not including primary residance'},
          { value: 'INCOME', label: 'Income', description: '$200k USD ($300k USD jointly with a spouse) of annual income for the last 2 years' },
          { value: 'LICENSED', label: 'License', description: 'Series 7, 65 or 82 license holder (active and in good standing)'},
        ],
      },
      COMPANY: {
        fields: [
          { name: 'legal_name', label: 'Full Legal Name', required: true },
          { name: 'phone_number', label: 'Phone Number', required: true },
          { name: 'email', label: 'Email ID', required: true },
        ],
        accreditation: [
            { value: 'TOTAL_ASSETS', label: 'Total assests' , description: 'A trust, with total assets in excess of $5 million, not formed specifically to purchase the subject securities, whose purchase is directed by a sophisticated person.'},
            { value: 'TOTAL_INVESTMENTS', label: 'Total investments', description: 'An entity with total investments in excess of $5 million, not formed to specifically purchase the subject securities.' },
            { value: 'ACCREDITED_OWNERS', label: 'Accredited owners', description: 'An entity in which all of the equity owners are accredited investors.' },
        ],
    },
}
  