import React from 'react';
import { useMantineTheme } from '@mantine/core';
import { InvestmentStatus } from 'shared/enums';

interface StatusDropdownProps {
  data: {
    id: string;
    status: keyof typeof InvestmentStatus;
    amount: string;
  };
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({ data }) => {
  const theme = useMantineTheme();
  const status = InvestmentStatus[data.status as keyof typeof InvestmentStatus];
  return <span style={{ color: status.value === InvestmentStatus.COMPLETED.value ? theme.colors.green[6] : theme.colors.red[6] }}>{status.label}</span>;
};

export default StatusDropdown;
