import React,  { useState } from 'react';
import { Button } from '@mantine/core';
import WiringComponent from 'components/Common/Wired/WiringComponent';
import { InvestmentStatus } from 'shared/enums';

const WiredData = ({ data }: { data: any }) => {
    const [openWiringModel, setWiringModel] = useState<boolean>(false);
    const { amount, status } = data;

    const { value: statusVal, label, rank } = InvestmentStatus[status as keyof typeof InvestmentStatus];

    return (
        <>
          {openWiringModel && (
            <WiringComponent
              isOpen={openWiringModel}
              handleClose={() => setWiringModel(false)}
              activeStage={rank}
              amount={amount}
              currency="$"
              data={{
                ...data, // Spread data object
                company_name: data.company // Override or add properties
              }}
              showDealsTerm={false}
            />
          )}
          { statusVal !== InvestmentStatus.COMPLETED.value && 
             <Button compact variant="outline" radius="sm" size="xs"  onClick={() => setWiringModel(true)}>Wire info</Button>
          } 
        </>
      );      
}

export default WiredData;