import React from 'react';
import { Card } from '@mantine/core';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const PortfolioCard = ({
  company,
  investedAmount,
  shares,
  perShareAmount,
  securityType,
}: {
  company: string;
  investedAmount: number;
  shares: number;
  perShareAmount: number;
  securityType: string;
}) => (
  <Card shadow="sm" radius="lg" className="settings-card">
    <h2>
      Invested ${formatter.format(investedAmount)} on {company}
    </h2>
    <p>
      <span className="font-bold">Shares :</span> {shares} (${formatter.format(perShareAmount)} per share)
    </p>
    <p>
      <span className="font-bold">Security Type :</span> {securityType}
    </p>
  </Card>
);

export default PortfolioCard;
