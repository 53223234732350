import React from 'react';
import { Loader } from '@mantine/core';
import AGGridTable from './AGGridTable'; 
import ChartIcon from 'assets/bar-chart.png';
import PortfolioCard from './PortfolioCard';
import { useGetPortfolioWithAuth } from '../hooks/useGetPortfolioWithAuth'; // Import your custom hook
import './Investors.scss';

const formatNumber = (price: string): string => Intl.NumberFormat('en-US').format(Number(price));

const DashboardPortfolio: React.FC = () => {
  const { data, error, isLoading, isAuthLoading, isAuthenticated } = useGetPortfolioWithAuth();

  if (isAuthLoading || isLoading) {
    return (
      <div className="investors-loader-wrapper">
        <Loader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="investors-loader-wrapper">
        <p>Please login to view this page</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="investors-loader-wrapper">
        {`Something went wrong: ${error}`}
      </div>
    );
  }

  const investments = data?.investments || [];
  const commitments = data?.commitments || [];
  const totalInvested = data?.total_invested || 0;

  return (
    <div className="investors-page">
      {data ? (
        <>
          <div className="investors-dashboard-container">
            <PortfolioCard
                subtitle="Total Investment"
                title={`$${formatNumber(totalInvested.toString())}`} 
                icon={ChartIcon}
                holdings={investments.length}
            />
          </div>
          <h4>List of Investments</h4>

          <div className="investors-table-container">
            <AGGridTable rowData={[...commitments, ...investments]} />
          </div>
        </>
      ) : (
        <div className="investors-loader-wrapper">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default DashboardPortfolio;
