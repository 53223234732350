import React from 'react';

import { Box, Center, Grid, Group, Skeleton, Stack } from '@mantine/core';

const InvestmentPageSkeleton: React.FC = () => (
  <>
    <Stack spacing="xs">
      <Group>
        <Skeleton height={56} width={56} />
        <Skeleton height={56} width={256} />
      </Group>
      <Skeleton width={300} height={24} />
      <Group spacing="xs">
        <Skeleton width={92} height={24} />
        <Skeleton width={92} height={24} />
        <Skeleton width={92} height={24} />
        <Skeleton width={92} height={24} />
        <Skeleton width={92} height={24} />
      </Group>
    </Stack>
    <Grid className="responsive-grid" gutter={72}>
      <Grid.Col span={8}>
        <Skeleton height={410} my={10} />
      </Grid.Col>
      <Grid.Col span={4}>
        <Stack spacing="xs">
          <Skeleton height={300} my={10} />
          <Box>
            <Skeleton height={50} mt={10} />
            <Center>
              <Skeleton height={20} width="50%" my={8} />
            </Center>
          </Box>
        </Stack>
      </Grid.Col>
    </Grid>
  </>
);

export default InvestmentPageSkeleton;
