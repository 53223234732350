import React, { useEffect, useState } from 'react';

import { Box, Loader, Text, Title } from '@mantine/core';
import { IconCircleCheck, IconLoader, IconInfoCircle } from '@tabler/icons';
import { useAuth0 } from '@auth0/auth0-react';

import './IdentitySettingsPage.scss';
import backendConfig from 'config/backend.config';
import { Link } from 'react-router-dom';
import Persona from 'persona';

const IdentitySettingsPage: React.FC = () => {
  const baseUrl = process.env.REACT_APP_AUTH0_REDIRECT_URI || '';
  const { getAccessTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const [inquiryStatus, setInquiryStatus] = useState<
    'LOADING' | 'PENDING' | 'COMPLETED' | 'FAILED' | 'EXPIRED' | 'APPROVED' | 'NEEDS_REVIEW' | 'DECLINED'
  >('LOADING');
  const [inquiryId, setInquiryId] = useState('');
  const [lastVisited, setLastVisited] = useState<any>(null);

  const getInquiryInfo = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const getInquiryInfoUrl = new URL(`v1/inquiries`, backendConfig.baseUrl);
      const res = await fetch(getInquiryInfoUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*',
        },
      });

      const data = await res.json();
      setInquiryId(data ? data.inquiryId : null);
      setInquiryStatus(data ? data.status : null);

      if (data.status === 'EXPIRED' || data.status === 'PENDING') {
        const refreshInquirySessionUrl = new URL(`v1/inquiries/${data.inquiryId}/resume`, backendConfig.baseUrl);
        const response = await fetch(refreshInquirySessionUrl, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*',
          },
        });
        const sessionData = await response.json();
        setSessionToken(sessionData);
      }
    } catch (error) {
      console.log('KYC Error', error);
    }
  };

  useEffect(() => {
    getInquiryInfo();
  }, [inquiryStatus]);

  useEffect(() => {
    const item = localStorage.getItem('last-visited');
    if (item) {
      setLastVisited(JSON.parse(item));
    }
  }, []);

  const getComponent = () => {
    if (inquiryStatus === 'APPROVED') {
      return (
        <div className="success-banner">
          <IconCircleCheck color="#4BB543" />
          <Title order={5} color="#4BB543">
            Congrats! You have been verified successfully
          </Title>
        </div>
      );
    }

    if (inquiryStatus === 'COMPLETED') {
      return (
        <div>
          <div
            className="success-banner"
            style={{
              backgroundColor: '#f5e5ff',
            }}
          >
            <IconLoader color="#bd86fa" />
            <Title order={5} color="#bd86fa">
              Thanks for uploading your documents, we&apos;ll send you an email confirmation once your KYC is approved.
              This can take a few minutes.
            </Title>
          </div>
          {lastVisited && (
            <p>
              In the meantime, checkout{' '}
              <Link
                style={{
                  color: '#bd86fa',
                  textDecoration: 'underline',
                }}
                to={`/${lastVisited?.id}`}
              >
                {lastVisited?.name}
              </Link>
            </p>
          )}
        </div>
      );
    }

    if (inquiryStatus === 'NEEDS_REVIEW') {
      return (
        <div>
          <div
            className="success-banner"
            style={{
              backgroundColor: '#f5e5ff',
            }}
          >
            <IconLoader color="#bd86fa" />
            <Title order={5} color="#bd86fa">
              Your application is currently being reviewed. You will receive an update as soon as possible.
            </Title>
          </div>
        </div>
      );
    }

    if (inquiryStatus === 'DECLINED' || inquiryStatus === 'FAILED') {
      return (
        <div>
          <div className="error-banner">
            <IconInfoCircle color="#FF3333" />
            <Title order={5} color="#FF3333">
              {inquiryStatus === 'DECLINED' ? 'Your application was not accepted' : 'Too many failed attempts'}
            </Title>
          </div>
          <p>
            Please contact{' '}
            <a
              style={{
                color: '#bd86fa',
                textDecoration: 'underline',
              }}
              href="mailto: support@tokenfolio.co"
            >
              support@tokenfolio.co
            </a>
          </p>
        </div>
      );
    }

    if (inquiryStatus === 'LOADING') {
      return (
        <div
          className=" flex justify-center"
          style={{
            marginTop: '40px',
            marginBottom: '40px',
          }}
        >
          <Loader />
        </div>
      );
    }

    return (
      <div className="personna-frame">
        <Persona.Inquiry
          inquiryId={inquiryId}
          sessionToken={sessionToken}
          templateId={inquiryId ? '' : process.env.REACT_APP_PERSONA_TEMPLATE_ID}
          referenceId={user?.sub}
          environmentId={process.env.REACT_APP_PERSONA_ENVIRONMENT_ID}
          onComplete={(_) => {
            setInquiryStatus('COMPLETED');
          }}
        />
      </div>
    );
  };

  return (
    <Box className="identity-settings">
      <Title order={1} my="lg">
        Identity verification
      </Title>
      <Text mt="md">
        Your identity information will be used to verify your identity. It will be stored securely and will not be
        shared without your permission, except as required by law.
      </Text>
      {getComponent()}
    </Box>
  );
};

export default IdentitySettingsPage;
