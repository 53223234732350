import React from 'react';

function withHOCDisplayName<T>(
  hoc: (WrappedComponent: React.ComponentType<T>) => React.FC<T>,
  hocDisplayName?: string
): (WrappedComponent: React.ComponentType<T>, displayName?: string) => React.FC<T> {
  return (WrappedComponent, displayName) => {
    const component = hoc(WrappedComponent);
    const wrappedComponentDisplayName =
      displayName ?? WrappedComponent.displayName ?? WrappedComponent.name ?? 'anonymous';
    component.displayName = `${hocDisplayName ?? hoc.name}(${wrappedComponentDisplayName})`;
    return component;
  };
}

export default withHOCDisplayName;
