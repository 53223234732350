import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SettingsView } from 'pages/settings/enum';
import type { RootState } from 'store/store';

type SettingsState = {
  currentView: SettingsView;
  shouldConfirm: boolean;
};

const initialState = {
  shouldConfirm: true,
} as SettingsState;

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentView: (state, { payload: currentView }: PayloadAction<SettingsView>) => ({
      ...state,
      currentView,
    }),
    setShouldConfirm: (state, { payload: shouldConfirm }: PayloadAction<boolean>) => ({
      ...state,
      shouldConfirm,
    }),
  },
});

export const { setCurrentView, setShouldConfirm } = slice.actions;

export const selectSettings = (state: RootState) => state.settings;

export const selectCurrentView = createSelector(selectSettings, (settings) => settings.currentView);
export const selectShouldConfirm = createSelector(selectSettings, (settings) => settings.shouldConfirm);

export default slice.reducer;
