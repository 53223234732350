import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Space } from '@mantine/core';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import Footer from 'layout/components/Footer/Footer';
import TopNavigation from 'layout/components/TopNavigation/TopNavigation';

import ProfilePreview from './components/ProfilePreview';
import SettingsNavigation from './components/SettingsNavigation';

const SettingsPageLayout: React.FC = () => (
  <>
    <TopNavigation />
    <Container>
      <ProfilePreview />
      <SettingsNavigation />
      <Space h="sm" />
      <Outlet />
    </Container>
    <Footer />
  </>
);

export default withAuthenticationRequired(SettingsPageLayout);
