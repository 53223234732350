import { dealsApi } from 'services/deals/deals.service';
import { combineReducers } from '@reduxjs/toolkit';
import { portfolioApi } from 'services/invest/portfolio.service';

import CompanySlice from 'pages/company/CompanySlice';
import SettingsSlice from 'pages/settings/SettingsSlice';
import { companyApi } from 'services/company/company.service';
import { investApi } from 'services/invest/invest.service';
import { entitiesApi } from 'services/invest/entities.service';
import { auth0Reducer } from 'components/Auth0/slices/auth0Slice'

export default combineReducers({
  [companyApi.reducerPath]: companyApi.reducer,
  [investApi.reducerPath]: investApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
  [portfolioApi.reducerPath]: portfolioApi.reducer,
  [entitiesApi.reducerPath]: entitiesApi.reducer,
  company: CompanySlice,
  settings: SettingsSlice,
  auth0: auth0Reducer,
});
