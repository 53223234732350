import ReactGA from 'react-ga4';

// Interface matching Auth0 user details
interface Auth0User {
  sub: string;            // Auth0 unique user ID
  email?: string;         // User email
  name?: string;          // User full name
  accountType?: string;   // Custom field for account type (if applicable)
}

class GoogleAnalyticsService {
  private user: Auth0User | null = null;

  constructor() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'G-7CSM20C9MJ'); 
  }

  // Method to set and store user details from Auth0
  public setUserDetails(user: Auth0User) {
    this.user = user;
  }

  // Method to send a page view with user details
  public sendPageView(url: string) {
    ReactGA.send({
      hitType: 'pageview',
      page: url,
      title: document.title,
      user_id: this.user?.sub,        // Use Auth0's 'sub' as the unique user ID
      email_id: this.user?.email,     // Email ID
      name: this.user?.name,          // User's full name
      account_type: this.user?.accountType, // Custom field (optional)
    });
  }

  // Method to send custom events with user details
  public sendCustomEvent(eventCategory: string, eventAction: string, eventLabel?: string, additionalData: Record<string, any> = {}) {
    ReactGA.send({
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
      ...additionalData,
      user_id: this.user?.sub,
      email_id: this.user?.email,
      name: this.user?.name,
      account_type: this.user?.accountType,
    });
  }
}

export default new GoogleAnalyticsService();
