import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchData } from 'utils/apiUtils';
import { setAuthContext } from './slices/auth0Slice'; // Adjust the path as needed
import backendConfig from 'config/backend.config';

// Define the context type
interface Auth0ContextType {
  commitments: any[] | null; // Adjust based on the expected structure
  // Add other context values if needed
}

// Create the context with default values
const Auth0Context = createContext<Auth0ContextType | undefined>(undefined);

export const Auth0Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const [commitments, setCommitments] = useState<any[] | null>(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchTokenAndDispatch = async () => {
      try {
        if (!fetched && user) {
          const token = await getAccessTokenSilently();
          dispatch(setAuthContext({ token, user }));

          const data = await fetchData({
            url: `${backendConfig.baseUrl}v1/commitments?status=COMMITTED`,
            method: 'GET',
            token,
          });

          setCommitments(data);
          setFetched(true);
        }
      } catch (error) {
        console.error('Failed to fetch token or data', error);
      }
    };

    fetchTokenAndDispatch();
  }, [getAccessTokenSilently, user, fetched, dispatch]);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(() => ({
    commitments,
  }), [commitments]);

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
};

// Custom hook to use the Auth0Context
export const useAuth0Context = (): Auth0ContextType => {
  const context = useContext(Auth0Context);
  if (context === undefined) {
    throw new Error('useAuth0Context must be used within an Auth0Provider');
  }
  return context;
};
